import React from "react";

const App = () => {
  return (
    <div className="container">
      <header className="header">
        <h1>Responsive React App</h1>
      </header>
      <main className="main">
        <section className="content">
          <h2>Content Section</h2>
          <p>
            This is a content section. Resize the browser to see how it
            responds!
          </p>
        </section>
        <section className="content">
          <h2>Another Content Section</h2>
          <p>Another piece of content that will adapt based on screen size.</p>
        </section>
      </main>
      <footer className="footer">
        <p>&copy; 2024 Responsive App</p>
      </footer>
    </div>
  );
};

export default App;
